body {
  background: #f1f1f1;
  margin: 0;
}

.sticky-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

/* dangerouslySetInnerHTML in ArticleView.js */
h1 {
  font-family: 'Inter', 'system-ui', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #414141;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0rem;
}

h2 {
  font-family: 'Inter', 'system-ui', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #414141;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0rem;
}

p {
  font-family: 'Inter', 'system-ui', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #6b6a6a;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
}

a {
  color: #4829B2;
  text-decoration: underline;
}

a:hover {
  color: #6b6a6a;
  text-decoration: none;
}